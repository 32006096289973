import React, { useState, useEffect } from 'react';
import styles from './InformesAluno.module.css';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Loading from '../../Loading'; // Assumindo que você tem um componente de carregamento separado
import apiAxios from '../../../api/apiAxios'; // Assumindo que você tem uma configuração de axios para chamadas API

const InformesAluno = () => {
    const [loading, setLoading] = useState(true);
    const [informes, setInformes] = useState([]);
    const [error, setError] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const months = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    useEffect(() => {
        async function fetchInformes() {
            try {
                const response = await apiAxios.get('/api/inf/'); // Substitua pela URL real da sua API
                setInformes(response.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }

        fetchInformes();

        const currentMonth = getCurrentMonth();
        setSelectedMonth(currentMonth);
    }, []);

    const handleMonthClick = (month) => {
        setSelectedMonth(month);
    };

    const getCurrentMonth = () => {
        const now = new Date();
        return months[now.getMonth()];
    };

    const getMonthFromDate = (dateString) => {
        const [day, month, year] = dateString.split(' ')[0].split('/');
        return parseInt(month, 10) - 1; // Subtrai 1 porque os meses no JavaScript são baseados em zero (0 = Janeiro, 11 = Dezembro)
    };

    const truncateDescription = (description,quantiti) => {
        return description.length > quantiti ? description.slice(0, quantiti) + '...' : description;
    };

    const filteredInformes = selectedMonth !== null
        ? informes.filter(informe => getMonthFromDate(informe.dateFormatted) === months.indexOf(selectedMonth))
        : informes;

    return (
        loading ? (
            <Loading />
        ) : (
            <div className={styles.screen}>
                <div className={styles.TopArea}>
                    <div className={styles.right}>
                        <Link to="/" className={styles.backLink}>
                            <IoIosArrowBack />
                        </Link>
                    </div>
                    <div className={styles.center}>
                        Informes
                    </div>
                    <div className={styles.left}>
                        ...
                    </div>
                </div>
                <div className={styles.BottomArea}>
                    <div className={styles.InformesMonth}>
                        <div>
                            {months.map((month, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleMonthClick(month)}
                                    className={selectedMonth === month ? styles.selectedMonth : ''}
                                    style={selectedMonth === month ? { color: "rgb(107, 107, 107)", fontWeight: "600", background: "aliceblue" } : {}}
                                >
                                    {month}
                                </button>
                            ))}
                        </div>
                    </div>
                    {error ? (
                        <p>Ocorreu um erro: {error}</p>
                    ) : (
                        <div className={styles.ListInformes}>
                            {filteredInformes.length > 0 ? (
                                filteredInformes.map((informe) => (
                                    <div key={informe.id} className={styles.informeItem}>
                                        <div className={styles.img}>
                                            <img src={informe.imagem} alt="img"/>
                                        </div>
                                        <div className={styles.conteudo}>
                                            <div className={styles.topico}>{truncateDescription(informe.topico,20)}</div>
                                            <div className={styles.titulo}>{truncateDescription(informe.titulo,20)}</div>
                                            <div className={styles.desc}>{truncateDescription(informe.descricao,50)}</div>
                                            <div className={styles.date}>{informe.dateFormatted}</div>
                                        </div>
                                    </div> 
                                ))
                            ) : (
                                <p>Nenhum informe encontrado para {selectedMonth}.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

export default InformesAluno;

import React, { useEffect, useState } from 'react';
import styles from './CardapioAluno.module.css';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Loading from '../../Loading';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';

const CardapioAluno = () => {
  const [loading, setLoading] = useState(true);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
  const tenantId = tokenPayload.tenantId;
  const [card, setCard] = useState([]);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const daysOfWeek = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];

  useEffect(() => {
    async function fetchCardapios() {
      try {
        const response = await apiAxios.get(`/api/card/${tenantId}`);
        setCard(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }

    fetchCardapios();
  }, [tenantId]);

  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
  };

  const filteredCardapios = card.filter(cardapio => {
    const cardapioMonth = new Date(cardapio.dateFormatted).getMonth();
    return cardapioMonth === selectedMonth;
  });

  return (
    loading ? (
      <Loading />
    ) : (
      <div className={styles.screen}>
        <div className={styles.TopArea}>
          <div className={styles.right}>
            <Link to="/" className={styles.backLink}>
              <IoIosArrowBack />
            </Link>
          </div>
          <div className={styles.center}>
            Cardápio
          </div>
          <div className={styles.left}>
            ...
          </div>
        </div>
        <div className={styles.listCardapio}>
          <div className={styles.monthsList}>
            <div>
              {months.map((month, index) => (
                <button
                  key={index}
                  className={`${styles.monthButton} ${selectedMonth === index ? styles.selected : ''}`}
                  onClick={() => handleMonthChange(index)}
                >
                  {month}
                </button>
              ))}
            </div>
          </div>
          {filteredCardapios.length === 0 ? (
            <p>Nenhum cardápio encontrado para este mês.</p>
          ) : (
            filteredCardapios.map((cardapio) => (
              <div className={styles.table_all} key={cardapio._id}>
                <h2>{cardapio.Titulo} ({new Date(cardapio.dateFormatted).toLocaleDateString()})</h2>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Refeição</th>
                      {daysOfWeek.map((day, index) => (
                        <th key={index}>{day}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {cardapio.alimentacao.map((alimentacao, index) => (
                      <tr key={index}>
                        <td  className={styles.refeicao}>{alimentacao.refeicao}</td>
                        {daysOfWeek.map((day, dayIndex) => {
                          const conteudo = alimentacao.conteudo.find(c => c.diaDaSemana === day);
                          return (
                            <td key={dayIndex}>
                              {conteudo && conteudo.ingredientes
                                ? Array.isArray(conteudo.ingredientes)
                                  ? conteudo.ingredientes.join(', ')
                                  : conteudo.ingredientes
                                : 'N/A'}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))
          )}
        </div>
      </div>
    )
  );
}

export default CardapioAluno;
